import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { MenuItem, MessageService } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { constants, UserRoles } from '../../../shared/constants/constants';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Reviewer, Tenant } from '../../../store/reviewer/reviewer.interface';
import { StorageService } from '../../../shared/services/storage.service'
import { Store } from '@ngrx/store';
import { BadgeModule } from 'primeng/badge';
import { EmittersService } from '../../../shared/services/emitters.service';
import { FirebaseMessagingService } from '../../../shared/services/firebase-messaging.service';
import { SkillAndCredentialService } from '../../../shared/services/skill-credential.service';
@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [RippleModule, PanelMenuModule, CommonModule, BadgeModule],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  providers: [MessageService, UserService],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() from: string = 'dashboard';
  items: MenuItem[] = [];
  additionalItems: MenuItem[] = [];
  countSubscriber:any = [];
  private subscription: Subscription = new Subscription;
  userRole: string = '';
  unreadCountInterval:any;
  onboardingItems = [
    {
      label: 'Onboarding',
      icon: 'space_dashboard',
      role: [UserRoles.reviewer]
    },
  ]  
  mailToContact: string = 'info@mplace.io';
  selectedTab = 'Dashboard';
  user: any = null;
  credentials = [];
  skillsAndExperience = [];
  reviewer!: Reviewer;
  messageCount:number=0;
  constructor(private router: Router, private userService: UserService, private reviewerService: ReviewerService, private authService: AuthService,
    private storageService: StorageService, private store: Store, private eventEmitterService: EmittersService,
    private firebaseMessagingService: FirebaseMessagingService, public skillAndCredentialService: SkillAndCredentialService
  ) {
    this.user = this.userService.getSessionUser();
    this.userRole = this.storageService.getCookieValueByKey('role');
    this.firebaseMessagingService.initFirebase();
  }

  ngOnInit() {
    this.reviewer = this.reviewerService.getReviewer();
    if (this.from === 'dashboard') {
      this.initializeItems();
    } else {
      this.items = this.onboardingItems;
    }
    
    this.unreadCountInterval = setInterval(()=>{
      if(this.countSubscriber.length < 1){
        this.initializeUnreadMessageCount();
      }
    },2000);
    
    if(UserRoles.reviewer === this.userRole){
      this.initializeCredentialAndSkills();
      this.subscribeEvents();
      
    }
  }

  initializeUnreadMessageCount(){
    
    if(this.userRole !== constants.userRoles.reviewer){
      this.getUnreadMessageCount();
    }else{
      this.getReviewerUnreadMessageCount();
    }
  }

  reEvaluateReviewerUnreadCount(){
    this.countSubscriber.forEach((o:any)=>{
      o.unsubscribe();
    });

    this.getReviewerUnreadMessageCount();

  }

  subscribeEvents(){
    this.eventEmitterService.tenantEmitter.subscribe(async () => {
      this.initializeCredentialAndSkills();
      this.reEvaluateReviewerUnreadCount();
    });

    this.eventEmitterService.skillAndExperienceEmitter.subscribe(() => {
      this.getSkillAndExperience();
    });

    this.eventEmitterService.credentialEmitter.subscribe(() => {
      this.getCredential();
    });
  }

  initializeCredentialAndSkills(){
    if (this.from === 'dashboard') {
      this.initializeItems();
    }
   
    this.getSkillAndExperience();
    this.getCredential();
  }

  getSkillAndExperience(): void{
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((skillsAndExperience) => {
      this.skillsAndExperience = skillsAndExperience;
      if (this.from === 'dashboard') {
        this.initializeItems();
      }
      this.skillAndCredentialService.setSkills(true);
      if(!this.skillsAndExperience || !this.skillsAndExperience.length){
        this.skillAndCredentialService.setSkills(false);
        this.navigateToGeneralIfCurrentPageDataNotFound('reviewer/profile/general', '/reviewer/profile/skills');
      }
    })
  }

  getCredential(): void{
    this.reviewerService.getFilteredCredentialBasedUserSkills(this.reviewer._id).subscribe((credential) => {
      this.credentials = credential;
      if (this.from === 'dashboard') {
        this.initializeItems();
      }
      this.skillAndCredentialService.setCredentials(true);
      if(!this.credentials || !this.credentials.length){
        this.skillAndCredentialService.setCredentials(false);
        this.navigateToGeneralIfCurrentPageDataNotFound('reviewer/profile/general', '/reviewer/profile/credentials');
      }
    })
  }

  navigateToGeneralIfCurrentPageDataNotFound(navigateTo: string, currentRoute: string){
    if(this.router.url === currentRoute){
      this.goto(navigateTo)
    }
  }

  goto(url:string){
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.countSubscriber.forEach((o:any)=>{
      o.unsubscribe();
    })
    clearInterval(this.unreadCountInterval);
  }
  initializeItems(): void {

    const profileRoute = {
      label: 'Profile',
      icon: 'person',
      items: [
        {
          label: 'General Information',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/general']);
          },
          url: '/reviewer/profile/general'
        },
        {
          label: 'Education',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/education']);
          },
          url: '/reviewer/profile/education'
        },
        {
          label: 'Credentials',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/credentials']);
          },
          url: '/reviewer/profile/credentials'
        },
        {
          label: 'Skills & Experience',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/skills']);
          },
          url: '/reviewer/profile/skills'
        },
        {
          label: 'Resume & Certifications',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/resume']);
          },
          url: '/reviewer/profile/resume'
        },
        {
          label: 'Resume Generator',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/resume-generator']);
          },
          url: '/reviewer/profile/resume-generator'
        }
      ],
      role: [UserRoles.reviewer]
    }
    if(!this.credentials || !this.credentials.length){
      profileRoute.items = profileRoute.items.filter(item => item.label !== 'Credentials')
    }
    if(!this.skillsAndExperience || !this.skillsAndExperience.length){
      profileRoute.items = profileRoute.items.filter(item => item.label !== 'Skills & Experience')
    }
    this.items = [
      {
        label: 'Dashboard',
        icon: 'space_dashboard',
        command: () => {
          this.router.navigate(['/client/overview']);
        },
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Project',
        icon: 'folder_open',
        items: [
          {
            label: 'Started',
            icon: '',
            url: '/client/project/started',
            command: () => {
              this.router.navigate(['/client/project/started']);
            },
          },
          {
            label: 'Published',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/published']);
            },
            url: '/client/project/published'
          },
          {
            label: 'Unpublished',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/unpublished']);
            },
            url: '/client/project/unpublished'
          },
          {
            label: 'Archived',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/archived']);
            },
            url: '/client/project/archived'
          }
        ],
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'People',
        icon: 'people',
        items: [
          {
            label: 'All',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/all']);
            },
            url: '/client/people/all'
          },
          {
            label: 'Worked Together',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/workTogether']);
            },
            url: '/client/people/workTogether'
          },
          {
            label: 'Restricted',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/restricted']);
            },
            url: '/client/people/restricted'
          },
          {
            label: 'Talent Pool',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/talentPool']);
            },
            url: '/client/people/talentPool'
          }
        ],
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Messages',
        icon: 'chat',
        command: () => {
          this.router.navigate(['/client/messaging']);
        },
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Reports',
        icon: 'summarize',
        items: [
          {
            label: 'Payroll',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/payroll']);
            },
            url: '/client/reports/payroll'
          },
          {
            label: 'Invoice',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/invoice']);
            },
            url: '/client/reports/invoice'
          },
          {
            label: 'All working reviewers',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/all-working-reviewers']);
            },
            url: '/client/reports/all-working-reviewers'
          }
        ],
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Documents',
        icon: 'lab_profile',
        command: () => {
          this.router.navigate(['/client/documents']);
        },
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Firm Users',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/client/firm-users']);
        },
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      {
        label: 'Dashboard',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/reviewer/dashboard']);
        },
        role: [UserRoles.reviewer || UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
      profileRoute,
      {
        label: 'Messages',
        icon: 'chat',
        command: () => {
          this.router.navigate(['/reviewer/messages']);
        },
        role: [UserRoles.reviewer]
      },
      {
        label: 'Invoice Generator',
        icon: 'schedule',
        command: () => {
          this.router.navigate(['/reviewer/timekeeping']);
        },
        role: [UserRoles.reviewer]
      },

      {
        label: 'Past Projects',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/reviewer/past-projects']);
        },
        role: [UserRoles.reviewer]
      },
      {
        label: 'Settings',
        icon: 'settings',
        command: () => {
          this.router.navigate(['/reviewer/settings']);
        },
        role: [UserRoles.reviewer]
      }
    ];
    this.additionalItems = [
      {
        label: 'Settings',
        icon: 'settings',
        items: [
          {
            label: 'Job Types',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/job-types']);
            },
            url: '/client/settings/job-types'
          },
          {
            label: 'Search Markets',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/search-markets']);
            },
            url: '/client/settings/search-markets'
          },
          {
            label: 'Skills and Experience',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/dynamic-criteria']);
            },
            url: '/client/settings/dynamic-criteria'
          },
          {
            label: 'Credentialing',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/dynamic-credential']);
            },
            url: '/client/settings/dynamic-credential'
          },
          {
            label: 'Change Password',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/change-password']);
            },
            url: '/client/settings/change-password'
          }
        ],
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]

      },
      {
        label: 'Contact',
        icon: 'contact_support',
        url: `mailto:${this.mailToContact}`,
        role: [UserRoles.firmManager, UserRoles.projectOwner, UserRoles.projectManager]
      },
    ];
  }
  getMenuItemsByRole() {
    let items = this.items;
    if(!this.user.firm.isDocusignEnabled){
      items = this.items.filter(item => item.label !== 'Documents');
    }
    return items.filter((i) => { return i['role'].includes(this.userRole) });
  }
  getAdditionalMenuItemsByRole() {
    return this.additionalItems.filter((i) => { return i['role'].includes(this.userRole) });
  }

  getUnreadMessageCount(){
    this.countSubscriber.push(this.firebaseMessagingService.getFirmUnReadMessages().subscribe((unReadDiscussions:any)=>{
      let unreadCount = 0;
      Object.values(unReadDiscussions).forEach((o:any)=>{    
            unreadCount += o.pmUnReadMessageCount;      
      });
      this.messageCount = unreadCount; 
    }));
  }

  getReviewerUnreadMessageCount(){
    this.countSubscriber.push(this.firebaseMessagingService.getReviewerUnReadMessages().subscribe((unReadDiscussions:any)=>{
      let unreadCount = 0;
      let Tenant = this.storageService.getTenant();
      Object.values(unReadDiscussions).forEach((o:any)=>{
          if(Tenant.firm.id === o.firmId){
            unreadCount += o.reviewerUnreadMessageCount;
          }
      });
      this.messageCount = unreadCount; 
    }));

  }
 
}
